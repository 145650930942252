import { useState, useEffect } from 'react'
import classNames from 'classnames'

import { LIVE_SITE_URL } from '@constants'

import Link from 'components/Link'
import ApplyFlashMessage from 'components/ApplyFlashMessage'
import Logo from 'components/Logo'
// import Menu from '../Menu';
// import fetch from '../../../helpers/localStorage';

import s from './ApplyHeader.module.css'
import getCurrentSiteBusinessUnit from '../../helpers/getCurrentSiteBusinessUnit';
import isModellinkSite from '../../helpers/isModellinkSite';

//TODO: setup proptypes
function ApplyHeader({ className, headerFloat, uiColour, activeArea = null }) {
  const [activeFlashMessage, setActiveFlashMessage] = useState(null)
  // useEffect(
  //   () => {
  //     const closedFlashMessages = fetch('flashMessagesClosed', []);
  //     // console.log('qweqwe', cxlosedFlashMessages);
  //     if (globalMessage && !closedFlashMessages.includes(page.slug)) {
  //       setActiveFlashMessage(globalMessage);
  //     }
  //   },
  //   [globalMessage, page.slug],
  // );

  return (
    <div
      className={classNames(
        s.container,
        {
          [s.containerFloat]: headerFloat,
          [s.containerSteps]: activeArea !== null,
        },
        className
      )}
    >
      {(!activeArea && !isModellinkSite()) && <ApplyFlashMessage />}
      <div className={s.wrapper}>
        <Logo className={s.logo} colour="#fff" href={LIVE_SITE_URL} businessUnit={getCurrentSiteBusinessUnit()}/>
        {!isModellinkSite() && (
        <nav
          className={classNames(s.menu, { [s.menuDark]: uiColour === 'dark' })}
        >
          <Link
            type="Apply"
            href="start"
            className={classNames(s.link, {
              [s.linkActive]: activeArea === 'form',
            })}
          >
            Apply
          </Link>
          <Link
            type="Apply"
            href="open-call"
            className={classNames(s.link, {
              [s.linkActive]: activeArea === 'open-call',
            })}
          >
            Open Call
          </Link>
          <Link
            type="Apply"
            href="advice"
            className={classNames(s.link, {
              [s.linkActive]: activeArea === 'advice',
            })}
          >
            Advice
          </Link>
        </nav>
            )}
      </div>
    </div>
  )
}

export default ApplyHeader
