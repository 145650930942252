import { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SitesContext } from 'context/SitesContextProvider'

import Link from 'components/Link'

import s from './WordpressLink.module.css'

function WordpressLink({
  className,
  isExternalLink,
  url,
  label,
  children,
  link,
  ...props
}) {
  if (isExternalLink)
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(s.container, className)}
        dangerouslySetInnerHTML={{ __html: label }}
        {...props}
      >
        {children}
      </a>
    )

  return (
    <Link
      type={link.__typename}
      href={link.uri || link.slug}
      className={classNames(s.container, className)}
      {...props}
    >
      {children || label}
    </Link>
  )
}

WordpressLink.propTypes = {
  url: PropTypes.string,
  isExternalLink: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.object,
}
WordpressLink.defaultProps = {
  url: null,
  isExternalLink: false,
  label: null,
  className: null,
  link: null,
}

export default WordpressLink
