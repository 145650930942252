import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from 'components/Link'

import s from './Logo.module.css'

function Logo({ businessUnit, colour, className, href }) {
  const content = (businessUnit === 'modellink') ?
    (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 23" className="aq8IK" fill={colour}>
        <defs>
          <polygon points=".004 .243 13.214 .243 13.214 12.853 .004 12.853"></polygon>
          <polygon points=".156 .278 12.706 .278 12.706 22.662 .156 22.662"></polygon>
          <polygon points=".091 .243 11.902 .243 11.902 12.853 .091 12.853"></polygon>
          <polygon points="0 22.878 119.978 22.878 119.978 0 0 0"></polygon>
        </defs>
        <g transform="translate(25.412 10.025)">
          <path d="M2.38171765,6.54776176 C2.38171765,8.77639412 4.25724706,10.6752471 6.60924706,10.6752471 C8.96089412,10.6752471 10.8367765,8.77639412 10.8367765,6.54776176 C10.8367765,4.29443824 8.96089412,2.42061471 6.60924706,2.42061471 C4.25724706,2.42061471 2.38171765,4.29443824 2.38171765,6.54776176 M13.2141882,6.54776176 C13.2141882,10.1181735 10.3080706,12.8531441 6.60924706,12.8531441 C2.91042353,12.8531441 0.0036,10.1181735 0.0036,6.54776176 C0.0036,2.97768824 2.91042353,0.243055882 6.60924706,0.243055882 C10.3080706,0.243055882 13.2141882,2.97768824 13.2141882,6.54776176"></path>
        </g>
        <g transform="translate(41.294 .216)">
          <path d="M10.4593765,16.3314882 C10.4593765,14.0274294 9.00667059,12.0775029 6.44361176,12.0775029 C3.98608235,12.0775029 2.53302353,14.1285618 2.53302353,16.3314882 C2.53302353,18.5597824 3.93384706,20.6362088 6.44361176,20.6362088 C9.03278824,20.6362088 10.4593765,18.6612529 10.4593765,16.3314882 Z M10.3277294,20.4082382 L10.2747882,20.4082382 C9.32361176,21.7503559 7.81796471,22.6619 6.07337647,22.6619 C2.26902353,22.6619 0.155611765,19.6489 0.155611765,16.2553853 C0.155611765,13.0140765 2.40067059,10.0518118 5.96784706,10.0518118 C7.81796471,10.0518118 9.29714118,10.9633559 10.2747882,12.3812382 L10.3277294,12.3812382 L10.3277294,0.278164706 L12.7058471,0.278164706 L12.7058471,22.2566941 L10.3277294,22.2566941 L10.3277294,20.4082382 Z"></path>
        </g>
        <g transform="translate(57.53 10.025)">
          <path d="M9.52376471,5.18061471 C9.23294118,3.40826176 8.01776471,2.21801176 6.11576471,2.21801176 C4.26564706,2.21801176 2.89129412,3.48402647 2.60117647,5.18061471 L9.52376471,5.18061471 Z M2.46882353,6.90257059 C2.49494118,8.92792353 3.92258824,10.8271147 6.19447059,10.8271147 C8.01776471,10.8271147 8.99576471,9.94093824 9.894,8.52305588 L11.9018824,9.61183529 C10.7389412,11.6628941 8.57258824,12.8531441 6.16764706,12.8531441 C2.38941176,12.8531441 0.0907058824,10.1181735 0.0907058824,6.62420294 C0.0907058824,2.97768824 2.12541176,0.243055882 6.11576471,0.243055882 C10.0517647,0.243055882 11.9018824,3.02842353 11.9018824,6.54776176 L11.9018824,6.90257059 L2.46882353,6.90257059 Z"></path>
        </g>
        <polygon points="73.079 22.472 75.457 22.472 75.457 .494 73.079 .494"></polygon>
        <polygon points="79.994 22.472 82.372 22.472 82.372 .494 79.994 .494"></polygon>
        <path d="M87.138 22.4723529L89.5161176 22.4723529 89.5161176 10.6222794 87.138 10.6222794 87.138 22.4723529zM89.9650588 5.20341176C89.9650588 6.06455882 89.2249412 6.74813235 88.3267059 6.74813235 87.4284706 6.74813235 86.6887059 6.06455882 86.6887059 5.20341176 86.6887059 4.31723529 87.4284706 3.60829412 88.3267059 3.60829412 89.2249412 3.60829412 89.9650588 4.31723529 89.9650588 5.20341176zM96.5885647 12.2176L96.6408 12.2176C97.3809176 10.9512471 98.8604471 10.2676735 100.366447 10.2676735 103.854565 10.2676735 104.753153 12.5209971 104.753153 15.4081735L104.753153 22.4722176 102.375035 22.4722176 102.375035 15.6862029C102.375035 13.66085 102.084212 12.2933647 99.6535059 12.2933647 96.5885647 12.2933647 96.5885647 14.8757912 96.5885647 17.0286588L96.5885647 22.4722176 94.2108 22.4722176 94.2108 10.6221441 96.5885647 10.6221441 96.5885647 12.2176z"></path>
        <polygon points="111.655 15.079 116.305 10.622 119.449 10.622 114.165 15.535 119.978 22.472 116.886 22.472 112.474 17.079 111.655 17.864 111.655 22.472 109.277 22.472 109.277 .494 111.655 .494"></polygon>
        <polygon points="10.809 15.973 0 0 0 22.524 2.484 22.524 2.484 7.91 10.809 20.921 19.133 7.91 19.133 22.524 21.617 22.524 21.617 0"></polygon>
      </svg>
    )
    :
    (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1116.9 285.8"
      fill={colour}
    >
      <path d="M194,184.8C176.7,197,150.9,203,116.5,203,45.4,203,6.4,185.6,0,150.9L77,143c.3,3.5,4.5,7.6,12.6,11.9a50.2,50.2,0,0,0,26,6.8c21,0,35.6-7,35.6-18.7,0-9.5-8.7-15.5-25.8-18.5l-73-12.7c-30-5.2-44.8-23.6-44.8-55.1C7.6,38,16.8,23.6,35,14.1S76.4,0,104.7,0c36.7,0,62.7,4.3,78.7,12.8Q214,29,216.4,51.3l-72.8,4.1c-4.2-9.5-15.4-16.9-33.3-16.9-22.1,0-32.7,8.4-32.7,17.1s5,12.8,15.1,14.4l77,13c33.6,5.7,50.1,20.9,50.1,55.9C219.8,157.6,211.1,172.8,194,184.8Z" />
      <path d="M299,135.4c1.4,15.5,16.5,29.6,42,29.6,20.5,0,29.7-3.8,38.4-14.7l67.5,8.2c-14.9,24.7-51.6,43.9-106.7,43.9-31.4,0-57.1-7-77.3-20.9s-30.5-33.1-30.5-57.8q0-34.2,30.2-53.7c20.2-13,46.5-19.5,79.3-19.5,70.2,0,111.1,29.8,112.5,84.9Zm84.6-26.9q-4.7-25.2-42-25.2-35.7,0-42.9,25.2Z" />
      <path className="cls-1" d="M535.6,196.4H471.2V6.5h64.4Z" />
      <path d="M616.3,135.4c1.4,15.5,16.5,29.6,42,29.6,20.4,0,29.6-3.8,38.3-14.7l67.5,8.2c-14.8,24.7-51.5,43.9-106.7,43.9q-46.9,0-77.3-20.9c-20.4-13.8-30.5-33.1-30.5-57.8q0-34.2,30.3-53.7c20.1-13,46.4-19.5,79.2-19.5,70.3,0,111.2,29.8,112.6,84.9Zm84.5-26.9q-4.6-25.2-42-25.2-35.7,0-42.8,25.2Z" />
      <path d="M999.3,151.1C980,185.3,942.4,202.4,887,202.4c-53.8,0-106.9-24.1-106.9-78.4,0-51.3,50.6-73.5,106.9-73.5,58.5,0,94.9,13.8,110.6,49.4l-65.8,6.5c-9-14.4-19.3-21.2-44-21.2a42.8,42.8,0,0,0-29.4,11.1c-8.1,7.3-12.3,16.3-12.3,26.9,0,25.8,15.7,42,42.6,42,12,0,21-1.6,26.6-5.1s11.7-9.5,18.2-18.7Z" />
      <path d="M1116.9,96.1h-25.2v52.6c0,12.7,3.6,13.8,18.5,13.8h6.1v35.6a303.9,303.9,0,0,1-35,1.6c-24.3,0-35.8-1.4-43.7-9.2s-8.9-16.8-8.9-38.3V96.1h-18.8V59.2h18.8V14.7h63V59.2h25.2Z" />
      <path d="M75.6,284.7H67.7v-9.1c0-7,.1-12.1.2-15.6h-.1c-.9,2.2-2.3,5.4-4.4,9.4l-7.6,15.3H49.6l-6.9-13.5A108,108,0,0,1,37.5,260h-.1c.1,4.5.2,9.6.2,15.4v9.3H29.7V248.9H39l8.3,15.8A95,95,0,0,1,52.7,276h.1c.6-1.7,2.4-5.6,5.5-11.7l8-15.4h9.3Z" />
      <path d="M116.5,250.3a27.4,27.4,0,0,1,11.9-2.5c5.7-.1,12.7,1.7,17.7,6,3.2,2.7,5.4,7.4,5.4,12.9,0,14.1-12.7,19.1-23.1,19.1s-22.8-5.3-22.8-19C105.6,258.1,110.5,252.8,116.5,250.3Zm1.4,26.1c2.6,2.3,6.1,3.5,10.5,3.5a16,16,0,0,0,10.7-3.5c2.7-2.3,4-5.4,4-9.6a12.5,12.5,0,0,0-3.9-9.7c-2.7-2.3-6.3-3.5-10.8-3.5s-8.4,1.2-10.8,3.7a13.2,13.2,0,0,0-3.6,9.5A12.4,12.4,0,0,0,117.9,276.4Z" />
      <path d="M197.6,284.7H181.5V248.9h15.4q22.5,0,22.5,17.4C219.4,278.6,212.1,284.7,197.6,284.7Zm-1.5-29.8h-6.7v23.6h7c9.8,0,14.7-4,14.7-12.1S206.1,254.9,196.1,254.9Z" />
      <path d="M285,284.7H249.3V248.9h34.4v6H257.3v8.4H281v5.9H257.3v9.3H285Z" />
      <path className="cls-1" d="M345,284.7H314.9V248.9h8v29.6H345Z" />
      <path d="M463.5,284.7h-7.9v-9.1c0-7,.1-12.1.2-15.6h-.1a84.6,84.6,0,0,1-4.4,9.4l-7.6,15.3h-6.2l-6.9-13.5c-2.3-4.6-4.1-8.3-5.2-11.2h-.1c.1,4.5.3,9.6.3,15.4v9.3h-8V248.9h9.3l8.3,15.8a95,95,0,0,1,5.4,11.3h.1a115.1,115.1,0,0,1,5.6-11.7l8-15.4h9.2Z" />
      <path d="M491.7,284.7l17.6-35.8h8.3l17.9,35.8h-8.9l-4-8.3H504l-4,8.3Zm21.4-28.2-6.3,13.9h12.9l-6.5-13.9Z" />
      <path d="M602,284.7h-7.3l-14.3-15.3c-5-5.4-8-8.7-9-10.1h-.1c.2,2.8.3,8.5.3,17v8.4h-7.9V248.9h7.2l14.4,15.5,8.9,9.8h.1q-.3-5.2-.3-15.3v-10h8Z" />
      <path d="M630.1,284.7l17.6-35.8H656l17.9,35.8h-8.8l-4.1-8.3H642.4l-4,8.3Zm21.4-28.2-6.3,13.9h12.9l-6.5-13.9Z" />
      <path d="M734.7,258.9c-2.8-3.5-7-5.3-12.7-5.3a15.1,15.1,0,0,0-10.2,3.4c-2.7,2.2-4.1,5.5-4.1,9.8a12.5,12.5,0,0,0,4,9.6,15.2,15.2,0,0,0,10.2,3.5q6.1,0,9.9-3c2.4-2,3.7-4,3.8-6.1v-.2h-12v-5.5H743v19.6h-7.3v-4.2h-.1a19,19,0,0,1-13.7,5.2,27.6,27.6,0,0,1-15.5-4.4c-4.3-3-7.1-8.1-7.1-14.5a16.6,16.6,0,0,1,3.2-10.3,19.1,19.1,0,0,1,8.3-6.5,29,29,0,0,1,11.2-2.1c5.8,0,10.9,1.3,14.2,3.3a20.6,20.6,0,0,1,6.8,6Z" />
      <path d="M810.3,284.7H774.6V248.9h34.3v6H782.6v8.4h23.7v5.9H782.6v9.3h27.7Z" />
      <path d="M886.1,284.7h-7.9v-9.1c0-7,.1-12.1.2-15.6h-.1a84.6,84.6,0,0,1-4.4,9.4l-7.6,15.3h-6.2l-6.9-13.5c-2.3-4.6-4.1-8.3-5.2-11.2h-.1c.1,4.5.2,9.6.2,15.4v9.3h-7.9V248.9h9.3l8.3,15.8a95,95,0,0,1,5.4,11.3h.1a115.1,115.1,0,0,1,5.6-11.7l8-15.4h9.2Z" />
      <path d="M953,284.7H917.4V248.9h34.3v6H925.4v8.4H949v5.9H925.4v9.3H953Z" />
      <path d="M1021.3,284.7H1014l-14.3-15.3c-5-5.4-8-8.7-9-10.1h-.1c.2,2.8.3,8.5.3,17v8.4H983V248.9h7.3l14.3,15.5,8.9,9.8h.1q-.3-5.2-.3-15.3v-10h8Z" />
      <path d="M1087.2,255h-14.4v29.7h-8V255h-14.4v-6.1h36.8Z" />
    </svg>
  )

  const setClass = classNames(s.container, className)

  if (!href) return <div className={setClass}>{content}</div>

  return href.startsWith('http') ? (
    <a href={href} className={setClass}>{content}</a>
  ) : (
    <Link href={href} type="Site" className={setClass}>{content}</Link>
  )
}

Logo.propTypes = {
  small: PropTypes.bool,
  colour: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
}
Logo.defaultProps = {
  small: false,
  colour: '#000',
  className: null,
  href: null,
}

export default Logo
