import classNames from 'classnames'

import { LIVE_SITE_CMS } from '@constants'

import Layout from 'components/Layout'
import WordpressSEO from 'components/WordpressSEO'
import ApplyHeader from 'components/ApplyHeader'

import s from './ApplyLayout.module.css'

//TODO: setup proptypes
function ApplyLayout({
  headerFloat = false,
  uiColour,
  activeArea,
  className,
  children,
  ...props
}) {
  return (
    <Layout
      className={classNames(s.container, className)}
      footerLess
      {...props}
    >
      <WordpressSEO
        url="https://apply.selectmodel.com"
        title="Apply | Become a Model | Select Model Management"
        desc="Select Model Management"
        seo={{
          images: [
            {
              url: `${LIVE_SITE_CMS}/wp-content/uploads/2020/06/marielu-e1591267898727.jpg`,
            },
          ],
        }}
      />
      <ApplyHeader
        activeArea={activeArea}
        headerFloat={headerFloat}
        uiColour={uiColour}
      />
      {children}
    </Layout>
  )
}

export default ApplyLayout
