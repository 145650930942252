import PropTypes from 'prop-types'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { Html5Entities } from 'html-entities'

import { LIVE_SITE_URL } from '@constants';
import uppercaseFirst from 'helpers/uppercaseFirst'
import isModellinkSite from '../../helpers/isModellinkSite';

const htmlEntities = new Html5Entities()

function htmlDecode(string = '') {
  return htmlEntities.decode(string)
}

// TODO: Set default image url
function WordpressSEO({ url, title, seo, image, desc }) {
  const router = useRouter()

  let siteName
  let fallbackTitle
  let siteLogo
  if (isModellinkSite()) {
    siteName = 'Modellink'
    fallbackTitle = `${htmlDecode(title)} | ${siteName}`
    siteLogo = '/img/logo/modellink.jpg';
  } else {
    siteName = 'Select Model Management'
    fallbackTitle = `${htmlDecode(title)}${router.query?.site ? ` | Select ${uppercaseFirst(router.query.site)}` : ''} | ${siteName}`
    siteLogo = '/img/logo/selectmodel.jpg'
  }

  return (
    <NextSeo
      title={
        seo && seo.title
          ? htmlDecode(seo.title)
          : fallbackTitle
      }
      description={seo?.metaDesc || desc || siteName}
      openGraph={{
        title: seo?.title,
        url: `${LIVE_SITE_URL}${url}`,
        description: seo?.metaDesc,
        images: [
          {
            url: image || seo?.images?.[0]?.url || `${LIVE_SITE_URL}${siteLogo}`,
          },
        ],
      }}
    />
  )
}

WordpressSEO.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  image: PropTypes.string,
  seo: PropTypes.object,
}
WordpressSEO.defaultProps = {
  url: '',
  title: '',
  desc: null,
  image: '',
  seo: {},
}

export default WordpressSEO
