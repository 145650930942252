import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { parseCookies, setCookie } from 'nookies'
import dynamic from 'next/dynamic'

// import clearFlashMessage from '../../actions/flashMessage/clear';

import s from './ApplyFlashMessage.module.css'

//TODO: Setup proptypes
function ApplyFlashMessage({ site, message, dispatch, close, className }) {
  const container = useRef(null)
  const [visible, setVisible] = useState(true)
  const [height, setHeight] = useState(null)

  const [mounted, setMounted] = useState(true)

  const handleScroll = () => {
    const currentScroll = window.pageYOffset
    if (currentScroll > 0) setVisible(false)
    else setVisible(true)
  }

  const handleResize = () => {
    if (container.current)
      setHeight(container.current.getBoundingClientRect().height)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    if (container.current)
      setHeight(container.current.getBoundingClientRect().height)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  })

  // if (!message) return null

  const handleClose = () => {
    setCookie(null, 'flash-message-read', true, {
      sameSite: 'strict',
      maxAge: 30 * 24 * 60 * 60,
    })
    setMounted(false)
    // dispatch(clearFlashMessage(site.slug))
    // close()
  }

  const show = showFlashMessage()

  if (!mounted) return false

  return (
    <>
    {show && <div className={s.overlay}></div> }
      {show && <div className={s.popup}>
        <h3>Important Notice</h3>
        <p>
          Please be aware that individuals are falsely claiming to work for
          Select Model Management (and they have been using some of our staff
          names and e-mail signatures).
        </p>
        <p>
          If you are contacted by anyone claiming to represent us, please do
          not respond without first verifying their identity by promptly
          calling directly at one of our offices.
        </p>

        <div className={s.buttons}>
          <a href="https://www.selectmodel.com/london/beware-of-scammers/" className={s.infoButton}>
            Find out more
          </a>

          <button
            type="button"
            title="Close overlay"
            className={s.continueButton}
            onClick={handleClose}
          >
            Continue
          </button>
        </div>
      </div> }
</>
  )
}

function showFlashMessage() {
  const cookies = parseCookies()

  if (!cookies['flash-message-read']) {
    return true
  }
  return false
}

export default dynamic(() => Promise.resolve(ApplyFlashMessage), {
  ssr: false,
})
